import React from "react"
import styled from "styled-components"

const AdWrapper = styled.div`
  position: fixed;
  z-index: 99999;
  width: 100%;
  max-width: 340px;
  padding: 20px;

  /* top: 20px;
  left: 50%;
  transform: translateX(-50%); */

  top: 0;
  right: 0;
`

const AdContent = styled.div`
  background: #fff;
  border-radius: 2px;
  /* padding: 14px 16px 12px 16px; */
  padding: 12px 14px 10px 14px;
  font-family: sans-serif;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  cursor: pointer;
  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  line-height: 1.2;
  h1 {
    margin: 0;
    font-size: 15px;
    margin-bottom: 5px;
  }
  h2 {
    margin: 0;
    font-weight: normal;
    font-size: 14px;
  }
`

const Ad = props => (
  <AdWrapper as="iframe">
    <AdContent>
      <h1>Some Title</h1>
      <h2>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris ut
        efficitur ex. Fusce efficitur vehicula pulvinar.
      </h2>
    </AdContent>
  </AdWrapper>
)

export default Ad
