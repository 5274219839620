import React from "react"
import SEO from "../components/seo"
import CanvasCube from "@src/components/CanvasCube"
import AdSlot from "../components/Ad"
// import CanvasThreeBasic from "@src/components/CanvasThreeBasic"

const IndexPage = () => (
  <>
    <SEO title="Home" />
    <CanvasCube />
    <AdSlot />
    {/* <CanvasThreeBasic /> */}
    <h1>Cool</h1>
  </>
)

export default IndexPage
