import React, { useRef, useEffect, useState } from "react"
import { Canvas, useThree } from "react-three-fiber"

const CameraMain = () => {
  const camera = useRef()
  const { size, setDefaultCamera } = useThree()
  const frustumSize = 7
  const aspect = size.width / size.height
  useEffect(() => void setDefaultCamera(camera.current), [])
  return (
    <orthographicCamera
      ref={camera}
      args={[
        (frustumSize * aspect) / -2,
        (frustumSize * aspect) / 2,
        frustumSize / 2,
        frustumSize / -2,
        1,
        1000,
      ]}
      position={[20, 20, 20]}
      rotation-order={"YXZ"}
      rotation-y={Math.PI / 4}
      rotation-x={Math.atan(-1 / Math.sqrt(2))}
      onUpdate={self => self.updateProjectionMatrix()}
    />
  )
}

const Block = ({ x, y, z }) => {
  // const [hover, setHover] = useState(false)
  const [active, setActive] = useState(false)
  return (
    <mesh
      visible
      position={[x, y, z]}
      onHover={e => {
        setActive(!active)
        e.stopPropagation()
      }}
      onClick={e => {
        setActive(!active)
        e.stopPropagation()
      }}
    >
      <boxGeometry name="geometry" />
      <meshStandardMaterial
        name="material"
        color={!active ? "hsl(182, 100%, 50%)" : "hsl(52, 20%, 63%)"}
      />
    </mesh>
  )
}

function Content() {
  const { gl } = useThree()
  useEffect(() => void gl.setPixelRatio(2), [])
  const [blocks] = useState([1, 2, 3, 4])
  return (
    <group>
      <CameraMain />
      <group>
        {blocks.map(x =>
          blocks.map(y =>
            blocks.map(z => {
              return <Block key={`${x}-${y}-${z}`} x={x} y={y} z={z} />
            })
          )
        )}
      </group>
    </group>
  )
}

const CanvasCube = props => (
  <Canvas>
    <ambientLight color="#fff" intensity={1} />
    <pointLight color="#fff" intensity={2} position={[30, 30, 30]} />
    <Content />
  </Canvas>
)

export default CanvasCube
